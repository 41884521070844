<template>
    <div :key="'test' + test.id" class="mt-2 hover-cell" @click="$emit('edit', test)">
        <v-card>
            <v-card-text class="pa-2">
                {{ test.name }}
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        test: {
            type: Object,
            default: null,
        },
    },
};
</script>
<style scoped>
    .hover-cell {
        cursor: pointer;
    }
</style>
